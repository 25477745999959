<template>
  <div id="order-view-action-download">
    <b-overlay :show="fullOrderPdfLoading">
      <b-button
        id="order-view-action-download-btn"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="mb-75"
        block
        @click="generateFullOrderPdf()"
      >
        <feather-icon icon="DownloadIcon" size="20" class="align-middle" />
        <span class="ml-25 align-middle"> {{ $t('action.download') }} </span>
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import {printPdf} from "@/request/globalApi/requests/quoteRequests";

export default {
  name: 'OrderViewActionDownload',

  components: {
    SupplyDocument: () => import('@/components/SupplyDocument.vue'),
    OrderViewCancellationPolicies: () => import('@/views/order/OrderViewCancellationPolicies.vue'),
  },

  data() {
    return {
      fullOrderPdfLoading: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected']),
    ...mapGetters('quote', ['quoteTitle']),
  },

  methods: {
    generateFullOrderPdf() {
      this.fullOrderPdfLoading = true
      printPdf(this.quoteSelected.quote.id).finally(() => {
        this.fullOrderPdfLoading = false
      }).catch(() => {
        this.fullOrderPdfLoading = false
        this.toaster(this.$t('errors.pdf.cannot_generate_pdf'), 'danger', 'AlertTriangleIcon')
      })
      // this.$refs['full-order-pdf'].generateReport()
    },
  },
}
</script>
