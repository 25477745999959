<template>
  <div id="order-view-actions-manager">
    <b-overlay :show="quoteLoading">
      <b-card>
        <!-- Specific actions -->
        <OrderViewActionSendInvoice v-if="showSendInvoice && $can('TRIP_ACCOUNTING_EDIT')"></OrderViewActionSendInvoice>
        <OrderViewActionRequestInvoice v-if="showRequestInvoice && (!quoteSelected.quote.cancelled || (quoteSelected.quote.cancelled && quoteSelected.quote.cancellationPoliciesApplied))" />
        <component :is="actionsSpecComponent" :disabled="disabled" />
        <!-- Base actions -->
        <OrderViewActionLinkToInvoice v-if="showLinkInvoice" />
        <OrderViewActionDownload />
        <OrderViewActionMessage v-if="$can('CHAT_ADD')" />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import OrderViewActionDownload from '@/views/order/OrderViewActionDownload.vue'
import OrderViewActionMessage from '@/views/order/OrderViewActionMessage.vue'

export default {
  name: 'OrderViewActionsManager',

  components: {
    OrderViewActionDownload,
    OrderViewActionMessage,
    OrderViewActionsSpecAwaitingQuotation: () => import('@/views/order/OrderViewActionsSpecAwaitingQuotation.vue'),
    OrderViewActionsSpecAwaitingCustomerValidation: () => import('@/views/order/OrderViewActionsSpecAwaitingCustomerValidation.vue'),
    OrderViewActionsSpecAwaitingServicesCompleted: () => import('@/views/order/OrderViewActionsSpecAwaitingServicesCompleted.vue'),
    OrderViewActionsSpecDelivered: () => import('@/views/order/OrderViewActionsSpecDelivered.vue'),
    OrderViewActionsSpecPaid: () => import('@/views/order/OrderViewActionsSpecPaid.vue'),
    OrderViewActionsSpecClaimed: () => import('@/views/order/OrderViewActionsSpecClaimed.vue'),
    OrderViewActionsSpecPaymentError: () => import('@/views/order/OrderViewActionsSpecPaymentError.vue'),
    OrderViewActionsSpecDelivredExcludingAccept: () => import('@/views/order/OrderViewActionsSpecDelivredExcludingAccept.vue'),
    OrderViewActionsSpecCancelled: () => import('@/views/order/OrderViewActionsSpecCancelled.vue'),
    OrderViewActionSendInvoice: () => import('@/views/order/OrderViewActionSendInvoice.vue'),
    OrderViewActionRequestInvoice: () => import('@/views/order/OrderViewActionRequestInvoice.vue'),
    OrderViewActionLinkToInvoice: () => import('@/views/order/OrderViewActionLinkToInvoice.vue'),
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showSendInvoice: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'quoteLoading', 'isQuoteOwner']),
    ...mapGetters('quote', ['isQuoteCancelledOrRefused', 'paymentError', 'quoteTitle']),

    actionsSpecComponent() {
      const { state, cancelled, refused, cancellationPoliciesApplied } = this.quoteSelected?.quote
      if (refused) return ''
      if (cancelled && cancellationPoliciesApplied === null && this.isQuoteOwner) {
        if (state === 'awaiting_customer_validation' || state === 'awaiting_for_quotation') {
          return ''
        }

        return 'OrderViewActionsSpecCancelled'
      }
      if (cancelled && !this.isQuoteOwner && cancellationPoliciesApplied === true && this.paymentError) return 'OrderViewActionsSpecPaymentError'
      if (cancelled && (!this.isQuoteOwner || cancellationPoliciesApplied !== null)) return ''
      if (state === 'awaiting_for_quotation') return 'OrderViewActionsSpecAwaitingQuotation'
      if (state === 'awaiting_customer_validation') return 'OrderViewActionsSpecAwaitingCustomerValidation'
      if (state === 'awaiting_for_services_completed') return 'OrderViewActionsSpecAwaitingServicesCompleted'
      if (state === 'delivered' && this.quoteSelected.quote.paymentTerm !== 'on_contract' && !this.quoteSelected?.paymentIntent) return 'OrderViewActionsSpecDelivredExcludingAccept'
      if (state === 'delivered' && (this.quoteSelected.quote.paymentTerm === 'on_contract' || this.quoteSelected?.paymentIntent?.status !== 'succeeded') && !this.paymentError) return 'OrderViewActionsSpecDelivered'
      if (!this.quoteSelected.offerOrCommissionQuote && state === 'delivered' && this.quoteSelected?.paymentIntent?.status === 'succeeded' && !this.paymentError) return 'OrderViewActionsSpecPaid'
      if (state === 'claimed' && !this.quoteSelected.quote.claim.closed) return 'OrderViewActionsSpecClaimed'
      if (this.paymentError) return 'OrderViewActionsSpecPaymentError'
      return ''
    },

    showLinkInvoice() {
      return !!this.quoteSelected?.invoice && this.quoteSelected?.quote?.invoiceSent
    },
    showRequestInvoice() {
      if (this.quoteSelected?.quote?.invoiceSent || this.isQuoteOwner) return false
      if (this.quoteSelected?.quote?.state === 'delivered' && !this.paymentError) return true
      if (this.quoteSelected?.quote?.cancelled && this.quoteSelected?.quote?.cancellationPoliciesApplied !== null) return true
      if (this.quoteSelected?.quote?.state === 'claimed' && this.quoteSelected.quote.claim.closed) return true
      return false
    },
  },

  watch: {
    'quoteSelected.quote': {
      handler(quote) {
        this.showSendInvoice = this.checkIfShowSendInvoice(quote.lines)
      },
      deep: true,
    },
  },

  methods: {
    checkIfShowSendInvoice(quoteLines) {
      const { state, cancelled, cancellationPoliciesApplied, cancellationPolicyType, invoiceSent } = this.quoteSelected?.quote
      if (invoiceSent) return false
      if (!this.isQuoteOwner) return false
      if (cancelled && cancellationPolicyType !== null && cancellationPoliciesApplied && this.isQuoteOwner) return true
      if (cancelled && (cancellationPolicyType !== null || !cancellationPoliciesApplied) && this.isQuoteOwner) return false
      if (state === 'awaiting_for_quotation' || state === 'awaiting_customer_validation') return false
      if (state === 'claimed' && this.quoteSelected.quote.claim.closed) return true
      if (quoteLines.some(line => line.status === 'not_planned')) return false

      return true
    },
  },
}
</script>
