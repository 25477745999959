<template>
  <div id="order-view-action-message">
    <b-button
      id="order-view-action-message-btn"
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      variant="outline-secondary"
      class="mb-75"
      block
      @click.prevent="messageEdition = true"
    >
      <feather-icon icon="MailIcon" size="20" class="align-middle" />
      <span class="ml-25 align-middle"> {{ $t('common.message') }} </span>
    </b-button>
    <app-sidebar-form
      form-name="quotation-message"
      :edition="messageEdition"
      edition-mode-title="edit"
      :title="$t('common.message')"
      :entity="message"
      :external-request="sendMessage"
      :submit-btn-text="$t('action.send')"
      submit-btn-icon="SendIcon"
      submit-btn-variant="secondary"
      @update:edition="messageEdition = $event"
    >
      <template #fields="data">
        <b-row>
          <b-col cols="12">
            <!-- message -->
            <validation-provider #default="{ errors }" :name="$t('order.message.text')" rules="">
              <b-form-group :label="$t('order.message.text')" label-for="quotation-message-text">
                <b-form-textarea
                  id="quotation-message-text"
                  v-model="message.content"
                  :placeholder="$t('order.message.text')"
                  no-resize
                  rows="4"
                  class="hide-scroll"
                  max-rows="4"
                  :state="errors[0] ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import { patchMessageChatRequest } from '@/request/globalApi/requests/chatRequests'
import { mapState } from 'vuex'

export default {
  name: 'OrderViewActionMessage',

  components: {
    AppSidebarForm,
  },

  data() {
    return {
      messageEdition: false,
      message: { content: '' },
    }
  },
  computed: {
    ...mapState('quote', ['quoteSelected']),
    ...mapState('auth', ['selectedOrganization']),
  },
  methods: {
    sendMessage() {
      if (this.message.content.length > 0) {
        let organizationId = this.quoteSelected.quote.organization.id
        // Si l'utilisateur connecté est le vendeur, alors on envoie un message au client
        if (this.selectedOrganization.id === this.quoteSelected.quote.organization.id) {
          organizationId = this.quoteSelected.quote.client.id
        }

        return patchMessageChatRequest(this.message.content, organizationId).then(() => {
          this.messageEdition = false
        })
      }

      return null
    },
  },
}
</script>

<style lang="scss" scoped>
.hide-scroll::-webkit-scrollbar {
  display: none;
}
</style>
